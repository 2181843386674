@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    background-color: black;
    color: white;
    height: 100%;
}

.react-tel-input .form-control {
    width: 100% !important;
    height: 2.5rem !important;
}

.otp-container div:first-child {
    gap: 1rem;
}

.otp-container div > input {
    margin-right: 0;
    flex: 1;
    width: 2rem !important;
    height: 2rem !important;
    padding: 0 2px;
    outline: none;
    color: black;
}

.site-logo {
    height: 60px;
    width: 200px;
}