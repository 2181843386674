@media screen and (min-width:1500px) {
    .video-container-content {
        left: 30%;
    }
}

@media screen and (max-width:1024px) {
    .card-container{
        gap: .5rem;
    }

    iframe {
        height: 300px;
        width: 600px;
    }
}

@media screen and (max-width:768px) {
    .card-container{
        gap: .5rem;
    }

    .card-container-contents {
        width: 10rem;
        height: 20rem;
        font-size: small;
    }
    .card-container-contents img {
        width: 100%;
        height: 70%;
    }

    iframe {
        height: 300px;
        width: 350px;
    }

    .video-container-content {
        left: 15%;
    }
}

@media screen and (max-width:425px) {
    .card-container{
        display: grid;
        justify-content: center;
        grid-template-columns: repeat(2, 1fr);;
    }

    iframe {
        height: 150px;
        width: 250px;
    }

    .video-container-content {
        left: 10%;
    }
}

/* @media screen and (max-width:426px) {
    iframe {
        height: 200px;
        width: 350px;
    }

    .video-container-content {
        left: 10%;
    }
} */

@media screen and (max-width:320px) {
    .card-container{
        display: grid;
        justify-content: center;
        grid-template-columns: repeat(1, 1fr);;
    }

    .card-container-contents {
        width: 14rem;
        height: 25rem;
        font-size: small;
    }
    .card-container-contents img {
        width: 100%;
        height: 70%;
    }
}